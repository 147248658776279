import React from 'react';

const DressCode = () => {
    return (
        <div className="ticket">
            <div className="ticket-top">
                <div className="ticket-header">
                    <div className="head-text">Código de Vestimenta</div>
                </div>
            </div>
            <div className="dress-code">
                <h2>O QUE LEVAR EM CONTA?</h2>
                <ul>
                    <li>🌊 A cerimônia será realizada pé na areia, com a festa em um deck.</li>
                    <li>🌞 O casamento acontecerá predominantemente durante o dia.</li>
                    <li>🎨 As cores exclusivas são azul serenity para as madrinhas e lavanda para as mães.</li>
                    <li>👗 Lembramos que a cor branca não é aconselhável para vestidos de convidadas em casamentos. Aproveitem para usar cores que combinem com o ambiente praiano.</li>
                    <li>👗 Tecidos como crepe, organza, musseline e linho são perfeitos para o dress code. Cores claras e estampas florais também trazem um tom alegre, sem fugir da atmosfera praiana que o casamento oferece.</li>
                    <li>👡 Para os pés, as melhores opções são sandálias, sapatilhas ou chinelos. Nada de salto na areia!</li>

                    <li>🕶️ O uso de óculos de sol é um acessório que, além de compor o look, protege a visão para que você não perca nenhum detalhe da cerimônia.</li>
                </ul>
            </div>
            <div className="ticket-bottom">
                <div className="bottom-info">
                    <div className="depart">
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DressCode;
