import React from 'react';
import './TicketContainer.css'; // Certifique-se de que o caminho está correto

const TicketContainer = ({ children }) => (
  <div className="ticket-container">
    {children}
  </div>
);

export default TicketContainer;
