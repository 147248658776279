import React from 'react';

const Location = () => {
  const googleCalendarUrl = "https://calendar.google.com/calendar/event?action=TEMPLATE&tmeid=NG04Z29mOWF1bjJrM2Qxbjc4MmtrY2FxM3MganVuaW9yZmVycmVpcmEyMDA3QG0&tmsrc=juniorferreira2007%40gmail.com";
  return (
    <div className="ticket">
      <div className="ticket-top">
        <div className="ticket-header">
          <div className="head-text">Como chegar</div>
        </div>

        <div className="location">
          <h2>Localização</h2>
          <p>Use o Waze para dirigir até Papillon Beach Casamento na Praia:</p>
          <p>Endereço: Estrada Municipal da Fortaleza 5355, Praia Brava da Fortaleza, Ubatuba-SP</p>
          <div className="button-container">
            <a href="https://waze.com/ul/h6gzfhnqjd" target="_blank" rel="noopener noreferrer">
              <button className="action-button waze-button">Viajar com Waze</button>
            </a>
            <a href={googleCalendarUrl} target="_blank" rel="noopener noreferrer">
              <button className="action-button calendar-button">Salvar na minha agenda</button>
            </a>
            <a href="https://airbnb.com.br/s/Praia-da-Fortaleza--Ubatuba-~-SP/homes?flexible_trip_lengths%5B%5D=one_week&monthly_start_date=2024-09-01&monthly_length=3&monthly_end_date=2024-12-01&query=Praia%20da%20Fortaleza%2C%20Ubatuba&place_id=ChIJlUdw3IxZzZQRdaFKcLKBSHA&location_bb=wbwj%2B8I0oVvBvFU3wjS1yA%3D%3D&refinement_paths%5B%5D=%2Fhomes&tab_id=home_tab&date_picker_type=calendar&checkin=2024-10-04&checkout=2024-10-06&source=structured_search_input_header&search_type=autocomplete_click" target="_blank" rel="noopener noreferrer">
              <button className="action-button airbnb-button">Encontrar um AirBnb</button>
            </a>
          </div>
        </div>

      </div>
      <div className="ticket-bottom">
        <div className="bottom-info">

          <div className="depart">

          </div>
        </div>
      </div>
    </div>
  );
}

export default Location;
