import React from 'react';

const Gifts = () => (
  <div className="ticket">
    <div className="ticket-top">
      <div className="ticket-header">
        <div className="head-text">Presentes</div>
      </div>

    </div>
    <div className="ticket-bottom">
      <div className="bottom-info">

        <div className="depart">

        </div>
      </div>
    </div>
  </div>
);

export default Gifts;
