import React, { useState, useEffect } from 'react';

const Ticket = ({ guests, integrationInfo }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentInviteGroup, setCurrentInviteGroup] = useState(null);
  const [guestSituations, setGuestSituations] = useState({});
  const [buttonText, setButtonText] = useState("Confirmar Presença");

  useEffect(() => {
    // When integrationInfo is available, map the situations to the guests
    if (integrationInfo) {
      const situations = integrationInfo.data.invitation.guests.reduce((acc, guest) => {
        acc[guest.id] = guest.situation;
        return acc;
      }, {});
      setGuestSituations(situations);
    }
  }, [integrationInfo]);

  const handleConfirmGroup = (inviteGroup) => {
    setCurrentInviteGroup(inviteGroup);
    setModalOpen(true);
  };

  const listaPresente = () => {
    window.open('https://loja.infinitepay.io/casamento-ingrid-junior', '_blank');
  };

  const submitConfirmation = async (idGuest, situation) => {
    try {
      // Update the state immediately to give visual feedback
      setGuestSituations(prevSituations => ({
        ...prevSituations,
        [idGuest]: situation,
      }));

      // Send the confirmation to the server
      const response = await fetch('https://api.ingridejunior.com.br/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'no-cors',
        body: JSON.stringify({ idGuest, situation }),
      });

      if (response.ok) {
        // Re-fetch integration info to confirm the update
        const newResponse = await fetch(`https://api.ingridejunior.com.br/guest/${integrationInfo.data.invitation.id}`);
        const newData = await newResponse.json();
        setGuestSituations(newData.data.invitation.guests.reduce((acc, guest) => {
          acc[guest.id] = guest.situation;
          return acc;
        }, {}));
      } else {
        console.error('Failed to update confirmation');
      }

      // Check if all guests are confirmed
      const allConfirmed = currentInviteGroup.every(
          guest => guestSituations[guest.idGuest] === 'CONFIRMED'
      );

      if (allConfirmed) {
        setButtonText("Mostrar QR para Entrada");
      }

    } catch (error) {
      console.error('Erro ao confirmar presença:', error);
    }
  };

  // Verifica se todos os convidados estão confirmados
  const allConfirmed = currentInviteGroup && currentInviteGroup.every(
      guest => guestSituations[guest.idGuest] === 'CONFIRMED'
  );

  useEffect(() => {
    // Atualiza o texto do botão quando todos estão confirmados
    if (allConfirmed) {
      setButtonText("Mostrar QR para Entrada");
    } else {
      setButtonText("Confirmar Presença");
    }
  }, [allConfirmed]);

  return (
      <div className="ticket">
        <div className="ticket-top">
          <div className="ticket-header">
            <div className="head-logo"></div>
          </div>
          <div className="ticket-body">
            <div className="body-info">
              <div className="event">
                <div className="event-info">
                  Evento
                  <h2>Casamento Ingrid & Júnior</h2>
                </div>
              </div>
              <div className="event-detail">
                <div className="flight-depart-date">
                  Cidade
                  <h2>Ubatuba</h2>
                </div>
                <div className="flight-depart-date">
                  Data
                  <h2>05 Out 24</h2>
                </div>
                <div className="flight-depart-time">
                  Horário
                  <h2>13:30</h2>
                </div>
              </div>
              <div className="event-detail">
                <div className="flight-depart-date">
                  Lugar
                  <h2>Areia do Papillon Beach</h2>
                </div>
              </div>
            </div>
            <div className="guest-info">
              <div className="info">
                <div className="info-name">
                  {Object.keys(guests).map((invite, index) => {
                    const inviteGroup = guests[invite];
                    return (
                        <div key={index}>
                          Convidados
                          {inviteGroup.guest.map((guest, gIndex) => (
                              <div key={gIndex} className="guest-entry">
                                <h2>{guest.nameGuest}</h2>
                              </div>
                          ))}
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="confirm-button-container" style={{margin: '0'}}>
              <button
                className="confirm-button"
                onClick={() => handleConfirmGroup(Object.values(guests).flatMap(group => group.guest))}
              >
                {buttonText}
              </button>
            </div>
            <div className="confirm-button-container" style={{marginTop: '0'}}> >
              <button
                className="confirm-button"
                onClick={() => listaPresente()}
              >
                Lista de Presentes
              </button>
            </div>
          </div>
        </div>
        <div className="ticket-bottom">
          <div className="bottom-info">
            <div className="depart">
              <div className="depart-terminal">
                Atrações
                <h2>
                  <a
                      href="https://www.instagram.com/brilhonoolharoficial/?hl=en"
                      className="no-link-style"
                  >
                    Pagode Brilho no Olhar
                  </a>
                </h2>
                <h2>
                  <a
                      href="https://www.instagram.com/djfabioary/"
                      className="no-link-style"
                  >
                    DJ Fábio Ary
                  </a>
                </h2>
              </div>
              <div className="depart-terminal">
                Traje
                <h2>Esporte Fino</h2>
              </div>
            </div>
            <div className="depart">
              <div className="depart-terminal">
                Endereço
                <a href="https://waze.com/ul/h6gzfhnqjd" className="no-link-style">
                  <h2>
                    Estrada Municipal da Fortaleza, 5355
                    <br />
                    Praia da Fortaleza Ubatuba - SP
                  </h2>
                </a>
              </div>
            </div>
            <div className="depart-barcode"></div>
          </div>
        </div>

        {modalOpen && currentInviteGroup && (
            <div className="modal">
              <div className="modal-content">
                <span className="close" onClick={() => setModalOpen(false)}>×</span>
                {!allConfirmed ? (
                    <>
                      <h2>Confirme a presença para:</h2>
                      {currentInviteGroup.map((casarmeGuest, cIndex) => (
                          <div key={cIndex} className="modal-guest-item">
                            <h3>{casarmeGuest.nameGuest}</h3>
                            <div className="confirmation-buttons">
                              <button
                                  className={`response-button maybe ${guestSituations[casarmeGuest.idGuest] === 'PENDING' ? 'active' : 'inactive'}`}
                                  onClick={() => submitConfirmation(casarmeGuest.idGuest, 'PENDING')}
                              >
                                <span className="material-symbols-outlined">priority_high</span>
                              </button>
                              <button
                                  className={`response-button yes ${guestSituations[casarmeGuest.idGuest] === 'CONFIRMED' ? 'active' : 'inactive'}`}
                                  onClick={() => submitConfirmation(casarmeGuest.idGuest, 'CONFIRMED')}
                              >
                                <span className="material-symbols-outlined">check_circle</span>
                              </button>
                              <button
                                  className={`response-button no ${guestSituations[casarmeGuest.idGuest] === 'CANCELED' ? 'active' : 'inactive'}`}
                                  onClick={() => submitConfirmation(casarmeGuest.idGuest, 'CANCELED')}
                              >
                                <span className="material-symbols-outlined">cancel</span>
                              </button>
                            </div>
                          </div>
                      ))}
                    </>
                ) : (
                    <div className="qr-code-container">
                      <p>Aqui está o QR Code do convite, que deve ser apresentado na recepção do evento.</p>
                      <img src={integrationInfo.data.invitation.qrCode} alt="QR Code" />
                      <p>Se preferir, tire um print dessa página caso esteja em um dispositivo móvel.</p>
                    </div>
                )}
              </div>
            </div>
        )}
      </div>
  );
};


export default Ticket;
