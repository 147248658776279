import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';

const Menu = () => {
  const [visible, setVisible] = useState(true);
  const inviteId = parseInt(sessionStorage.getItem('inviteId'))|| false;

  useEffect(() => {
    let timer;

    const handleInteraction = () => {
      setVisible(true);
      clearTimeout(timer);
      timer = setTimeout(() => setVisible(false), 100000);
    };

    window.addEventListener('mousemove', handleInteraction);
    window.addEventListener('touchstart', handleInteraction);

    // Limpar os eventos e o timer quando o componente desmonta
    return () => {
      window.removeEventListener('mousemove', handleInteraction);
      window.removeEventListener('touchstart', handleInteraction);
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className={`menu ${visible ? 'visible' : 'hidden'}`}>
      <div className="list">
        {inviteId && (
          <div className="item">
            <NavLink to={`/invite/${inviteId}`} activeClassName="active">
              <span className="material-symbols-outlined">confirmation_number</span>
              <span>Convite</span>
            </NavLink>
          </div>
        )}
        <div className="item">
          <NavLink to="/dress-code" activeClassName="active">
            <span className="material-symbols-outlined">styler</span>
            <span>Dress Code</span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/get-inspired" activeClassName="active">

            <span className="material-symbols-outlined">apparel</span>
            <span>Inspire-se</span>
          </NavLink>
        </div>
        <div className="item">
          <NavLink to="/location" activeClassName="active">
            <span className="material-symbols-outlined">near_me</span>
            <span>Local</span>
          </NavLink>
        </div>
        <div className="item">
          <a href="https://loja.infinitepay.io/casamento-ingrid-junior" target="_blank" rel="noopener noreferrer">
            <div className="menu-item animated-gift">
              <span className="material-symbols-outlined">featured_seasonal_and_gifts</span>
            </div>            <span>Presentes</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Menu;
