import React, { useState, useEffect } from 'react';

const images = [
  'https://i.pinimg.com/564x/c0/3e/93/c03e939f1e77497d4a4c3897cc6c5d78.jpg',
  'https://i.pinimg.com/564x/e3/d5/4d/e3d54d93a63690a58bd361e2355df147.jpg',
  'https://i.pinimg.com/564x/4c/3c/c4/4c3cc48a567b712daaffc17667d05f17.jpg',
  'https://i.pinimg.com/564x/35/26/a8/3526a8514035ab5730fa847a87b0f31c.jpg',
  'https://i.pinimg.com/564x/a1/7d/d4/a17dd4fbf582e39cff3b6d741e4f42dd.jpg',
  'https://i.pinimg.com/564x/35/3c/b0/353cb0199ec09aff9c333aa114fd631c.jpg',
  'https://i.pinimg.com/564x/46/62/5a/46625a2a109f1edc65d95737d257cc29.jpg',
  'https://i.pinimg.com/736x/4a/ea/3c/4aea3cb877432964b75fd2764f780189.jpg',
  'https://i.pinimg.com/564x/1f/57/18/1f5718f8decdb5f041275c1c4d87934e.jpg',
  'https://i.pinimg.com/564x/d8/20/66/d82066582f7c75288826fb7dd23af08c.jpg',
];

const Inspired = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000); // Troca de imagem a cada 5 segundos

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="ticket">
      <div className="ticket-top">
        <div className="ticket-header">
          <div className="head-text">Inspires-se</div>
        </div>
      </div>
      <div className="dress-code">
        <h2>LOOKS PARA INSPIRAÇÃO</h2>
        <div className="carousel">
          {images.map((image, index) => (
            <div
              key={index}
              className={`carousel-image ${index === currentImageIndex ? 'active' : ''}`}
              style={{
                backgroundImage: `url(${image})`,
              }}
            ></div>
          ))}
        </div>
      </div>
      <div className="ticket-bottom">
        <div className="bottom-info">
          <div className="depart"></div>
        </div>
      </div>
    </div>
  );
};

export default Inspired;
