import React, { useState, useEffect } from 'react'; // Adicione useState e useEffect à importação
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Menu from './components/Menu';
import InvitePage from './components/InvitePage';
import DressCode from './components/DressCode';
import Location from './components/Location';
import Gifts from './components/Gifts';
import TicketContainer from './components/TicketContainer'; // Certifique-se de importar o TicketContainer
import './index.css';
import Inspired from "./components/Inspired";

function App() {
  const location = useLocation();
  const [currentKey, setCurrentKey] = useState(location.key);

  useEffect(() => {
    setCurrentKey(location.key);
  }, [location]);

  return (
    <div className="App">
      <Menu />

          <div className="fade-wrapper">
            <Routes location={location}>
              <Route path="/" element={<Navigate to={`/invite/${sessionStorage.getItem('inviteId')}`} />} />
              <Route path="/invite/:inviteId" element={<TicketContainer><InvitePage /></TicketContainer>} />
              <Route path="/dress-code" element={<TicketContainer><DressCode /></TicketContainer>} />
              <Route path="/get-inspired" element={<TicketContainer><Inspired /></TicketContainer>} />
              <Route path="/location" element={<TicketContainer><Location /></TicketContainer>} />
              <Route path="/gifts" element={<TicketContainer><Gifts /></TicketContainer>} />
            </Routes>
          </div>

    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
